.profiles {
    padding-top: 50px;
    padding-bottom: 50px;
    border: 1px solid #e5ebf2;
    scroll-margin-top: 50px;
}

.profile-icon-desc {
    padding: 5px 15px;
    text-align: center;
    color: #ffffff;
    line-height: 15px;
}

.profiles-content {
    display: flex;
}

.profiles-content:first-child {
    padding-top: 20px;
}

.profiles-content .profile-wrapper,
.profile-border .profile-wrapper {
    padding: 0;
}

.profile-txt {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
}

.single-profile {
    position: relative;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #b2c1ce;
    overflow: hidden;
    -webkit-transition: background 0.5s linear;
    -moz-transition: background 0.5s linear;
    -ms-transition: background 0.5s linear;
    -o-transition: background 0.5s linear;
    transition: background 0.5s linear;
}

.profile-icon-name a {
    text-transform: capitalize;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    padding: 0 8px;
}

.profile-icon-subname a{
    text-transform: capitalize;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    padding: 0 10px;
}

.single-profile.profile-no-border {
    border-right: transparent;
}

.profile-border {
    border-bottom: 1px solid #b2c1ce;
}

.profile-topborder {
    border-top: 1px solid #b2c1ce;
}

.single-profile-overlay {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 100%;
    height: 100%;
    background: #e064a2;
    background: -moz-linear-gradient(left, #e064a2 0%, #d63384 100%);
    background: -webkit-linear-gradient(left, #e064a2 0%, #d63384 100%);
    background: linear-gradient(to right, #e064a2 0%, #d63384 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e064a2', endColorstr='#d63384', GradientType=1);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}

.single-profile:hover .single-profile-overlay {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    top: 0;
}

.single-profile:hover .profile-icon-name,
.single-profile:hover .profile-txt a>i {
    color: #fff;
}

.single-profile:hover .profile-border {
    border: transparent;
}

@media (max-width:768px) {
    .profile-wrapper {
        display: flex;
        width: 50%;
    }

    .single-profile {
        border: none;
        margin-top: 0px;
        margin-bottom: 0px;
        width: 100%;
    }

    .profile-border {
        display: none;
    }

    .profiles-content {
        padding-top: 0px;
        display: block;
    }

    .profiles-content:first-child {
        padding-top: 0;
    }

    .profiles {
        padding: 20px 0 0px;
    }

    .profiles .section-heading h2 {
        padding-bottom: 10px;
    }

    div.profile-icon-subname a{
        color: #ffffff;
        font-size: 16px;
    }
}