.experience {
    padding-top: 50px;
    padding-bottom: 50px;
    border-top: 1px solid #e5ebf2;
    scroll-margin-top: 50px;
}

.experience-content {
    padding-top: 0;
}

.single-timeline-box {
    margin-bottom: 75px;
}

.experience-time {
    position: relative;
    width: fit-content;
}

.experience-time h2 {
    font-weight: 400;
    text-align: left;
}

.experience-time h3 {
    font-size: 16px;
    font-weight: 300;
    color: #636a82;
    margin-top: 14px;
    text-transform: uppercase;
}

.experience-hl {
    color: #d63384;
}

.main-timeline {
    position: relative;
    margin-left: 8%;
}

.main-timeline:before {
    content: "";
    width: 2px;
    height: 96%;
    background: #b2c1ce;
    position: absolute;
    top: 1%;
    left: 46.3%;
    z-index: -1;
}

.main-timeline .timeline {
    position: relative;
}

.timeline-content span>svg,
.experience-time span>svg {
    color: #d92cf9;
    position: absolute;
    font-size: 10px;
    left: -10%;
    z-index: 1;
}

.timeline-content h4.title {
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
}

.timeline-content {
    margin-bottom: 20px;
}

.timeline-content h5 {
    color: #636a82;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 10px;
}

.main-timeline .description {
    font-size: 14px;
}

.main-timeline .timeline-content.right {
    float: right;
    text-align: left;
}

.timeline-single-before:before {
    content: '';
    top: 57px;
    left: -3px;
    position: absolute;
    width: 100%;
    height: 400px;
    border-left: 15px solid #fff;
}

.experience-time-responsive {
    display: none;
}

.date {
    display: none;
}

.last-title {
    display: block;
}

.timeline-box {
    width: 40%;
    float: left;
}

.single-timeline-box .timeline-box:first-child {
    display: flex;
    justify-content: flex-end;
}

.experiencedata {
    margin-left: 8.5%;
}

.experience-time-main {
    margin-left: 8.5%;
}

@media (max-width: 1399px) {
    .main-timeline:before {
        left: 46.6%;
    }
}

@media (max-width: 1199px) {
    .main-timeline:before {
        left: 47%;
    }
}

@media (max-width: 991px) {
    .date {
        display: block;
    }

    .main-timeline:before {
        left: 12px;
        height: 89%;
    }

    .single-timeline-box {
        display: flex;
        flex-direction: column;
    }

    .timeline-box {
        justify-content: flex-start !important;
    }

    .experience-time {
        text-align: left;
    }

    .experiencedata {
        margin-left: 0;
    }

    .timeline-box {
        width: 100%;
        justify-content: flex-start !important;
    }

    .timeline-content {
        text-align: left;
    }

    .experience-time-main {
        display: none;
    }

    .experience-time-responsive {
        display: block;
    }

    .timeline-content span svg,
    .experience-time span svg {
        left: -24px;
    }

    .timeline-content span svg {
        top: -55px;
    }

    .experience-time span svg {
        top: 7px;
    }
}

@media (max-width:768px) {
    .single-timeline-box {
        margin-bottom: 0px;
    }

    /* .timeline-box {
        justify-content: flex-start;
    } */

    .experience {
        padding: 20px 0 0px;
    }

    .experience-content {
        padding-top: 0px;
    }

    .experiencedata {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .datalist {
        padding-bottom: 0px;
    }

    .experience .section-heading h2 {
        padding-bottom: 10px;
    }


    .experience-time h3 {
        font-size: 16px;
        font-weight: 300;
        color: #636a82;
        margin-top: 5px;
        text-transform: uppercase;
    }

    .main-timeline:before {
        display: none;
    }

    .timeline-content .title span {
        display: none;
    }

    .experience-time span svg {
        display: none;
    }

    .main-timeline ul {
        padding-left: 0;
    }
}