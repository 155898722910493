.education {
    background: #ffffff;
    padding-top: 50px;
    padding-bottom: 50px;
    border-top: 1px solid #e5ebf2;
    scroll-margin-top: 50px;
}

.education-horizontal-timeline {
    padding: 80px 0;
}

.education-href {
    color: #4B0082;
}

.education-horizontal-timeline .col-sm-4 {
    padding: 0 2px;
}

.single-items {
    display: flex;
    flex-direction: row;
}

.single-item {
    width:calc(100%/3);
    padding: 10px;
}

.education-horizontal-timeline .single-item{
    width: 25%;
}

.single-horizontal-timeline .experience-time h3 {
    text-transform: capitalize;
}

.single-horizontal-timeline .experience-time h3 span {
    text-transform: lowercase;
}

.single-horizontal-timeline .timeline-content h5 {
    margin: 15px 0;
}

.single-horizontal-timeline p {
    max-width: 335px;
}

.timeline-horizontal-border {
    display: flex;
    align-items: center;
    padding: 26px 0 30px;
}

span.single-timeline-horizontal {
    display: inline-block;
    background: #b2c1ce;
    height: 1px;
    width: 380px;
}

.education .list {
    list-style-type: circle;
    margin-bottom: 15px;
    font-size: 15px;
}

.education .title {
    font-size: 25px;
    text-align: left;
}

@media(max-width: 768px) {
    .education {
        padding-top: 20px;
    }

    .education-horizontal-timeline {
        padding: 0px 0;
    }

    .education .section-heading h2 {
        padding-bottom: 10px;
    }

    .education .title {
        font-size: 20px;
        text-align: center;
    }

    .single-items {
        flex-direction: column;
    }

    .single-item {
        width: 100%;
    }
    span.single-timeline-horizontal {
        width: 100%;
    }
    .education-horizontal-timeline .single-item {
        width: 100%;
    }
}