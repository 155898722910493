table {
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #e6cbf7;
}

.skills-content {
    width: 100%;
    margin: auto;
    padding-top: 50px;
    margin-top: 50px;
    padding-bottom: 50px;
    border-top: 1px solid #e5ebf2;
    scroll-margin-top: 50px;
}

.skills-content h2 {
    padding: 20px;
}

.skills-box {
    display: flex;
    justify-content: center;
    /*align-items: center;*/
}

.table-box {
    font-size: 16px;
    width: 50%;
}

.mindMap {
    width: 50%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-image: url('../../assets/images/Mindmap.webp');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}

.image_modal {
    z-index: 9999;
}

@media(max-width:768px) {
    .skills-box {
        display: block;
    }

    .table-box {
        width: 100%;
        font-size: 10px;
    }

    .mindMap {
        width: 100%;
        height: auto;
    }

    .skills-content {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
}