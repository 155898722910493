.welcome-hero {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: url(../../assets/images/about/welcome-banner.webp) no-repeat;
    background-size: cover;
    background-position: center;
    height: 890px;
}

.welcome-hero:before {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    background: rgba(31, 44, 108, .65);
    width: 100%;
    height: 100%;
}

.header-text h2 {
    color: #fff;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.5;
    width: 80%;
    margin: auto;
}

.header-text h2 span {
    color: #fff;
    display: block;
    margin-top: 10px;
    line-height: 30px;
}

.carousel-indicators {
    margin: auto !important;
}

.carousel-inner {
    display: flex;
    align-items: center;
}

.header-text p {
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
    margin: 30px 0 60px;
    letter-spacing: 1px;
}

.header-text a {
    width: 200px;
    height: 60px;
    line-height: 60px;
    border-radius: 3px;
    text-transform: capitalize;
    color: #fff;
    background: #b636ff;
    border: 1px solid #b636ff;
    box-shadow: 0 5px 20px rgba(0, 0, 0, .2);
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -ms-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
}

.header-text a:hover {
    box-shadow: 0 5px 20px rgba(0, 0, 0, .4);
    background: #9f00ff;
    border: 1px solid #9f00ff;
}

.w-full {
    width: 100%;
}

@media (max-width:768px) {
    .header-text h2 {
        color: #fff;
        font-size: 25px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.5;
    }

    .header-text p {
        color: #fff;
        font-size: 15px;
        font-weight: 300;
        text-transform: uppercase;
        margin: 30px 0 60px;
        letter-spacing: 1px;
    }

    .header-text a {
        width: 170px;
        height: 35px;
        line-height: 35px;
        border-radius: 3px;
        text-transform: capitalize;
        color: #fff;
        background: #b636ff;
        border: 1px solid #b636ff;
        box-shadow: 0 5px 20px rgba(0, 0, 0, .2);
        -webkit-transition: 0.3s linear;
        -moz-transition: 0.3s linear;
        -ms-transition: 0.3s linear;
        -o-transition: 0.3s linear;
        transition: 0.3s linear;
    }
}