.top-area {
    position: relative;
    z-index: 2;
}

.custom-header {
    background-color: #fff;
    border: none;
    margin-bottom: 0;
    border-radius: 0;
    border-bottom: transparent;
    box-shadow: 0 3px 15px rgba(0, 0, 0, .2);
    position: fixed !important;
    width: 100%;
    height: 90px;
}

.custom-header a.navbar-brand,
.custom-header a.navbar-brand:hover,
.custom-header a.navbar-brand:focus {
    display: inline-block;
    color: #d92cf9;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
}

.me-auto a {
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -ms-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
}

.me-auto a {
    padding: 35px 20px !important;
}

.me-auto a:hover,
.me-auto a:focus,
.me-auto a {
    color: #6f6f6f;
}

nav.navbar.bootsnav ul.nav>li.dropdown>a.dropdown-toggle:after {
    content: "";
}

nav.navbar.bootsnav ul.nav>li.dropdown span {
    font-size: 8px;
    margin-left: 15px;
}

.sticky-wrapper.is-sticky nav.navbar.bootsnav ul.nav>li>a {
    color: #6a708e;
}

.sticky-wrapper.is-sticky nav.navbar.bootsnav ul.nav>li>a:hover,
.sticky-wrapper.is-sticky nav.navbar.bootsnav ul.nav>li>a:focus,
.sticky-wrapper.is-sticky nav.navbar.bootsnav ul.nav>li.active>a {
    color: #d92cf9;
}

.menu-ui-design {
    overflow-y: scroll;
    height: 350px;
}

.menu-ui-design::-webkit-scrollbar {
    width: 5px;
}

.menu-ui-design::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 8px #000;
}

.menu-ui-design::-webkit-scrollbar-thumb {
    background-color: #d92cf9;
}

nav.navbar.bootsnav .navbar-toggle i {
    color: #d92cf9;
}

.top-area.desktop {
    display: block;
}

.mobile {
    display: none;
    position: fixed;
}

.burger-icon {
    position: fixed;
    top: 20px;
    left: 10px;
    z-index: 10;
    float: right;
    width: 20px;
    height: 20px;
}

.bm-burger-button {
    display: none;
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
}

.bm-burger-bars {
    background: #373a47;
}

.bm-burger-bars-hover {
    background: #a90000;
}

.bm-cross-button {
    height: 24px;
    width: 24px;
}

.bm-cross {
    background: #bdc3c7;
}

.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

.bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

.bm-morph-shape {
    fill: #373a47;
}

.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

.bm-item {
    display: inline-block;
}

.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.menu-item {
    padding-bottom: 20px;
    color: #fff;
    text-transform: uppercase;
}

@media (max-width: 992px) {
    .top-area.desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}

@media (max-width: 768px) {
    .bm-burger-button {
        display: block;
    }
}