.hm-foot-icon ul li {
    float: left;
}

.hm-foot-icon ul li>a {
    color: #636a82;
    margin-right: 18px;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.hm-foot-icon ul li>a:hover {
    color: #d63384;
}

.hm-footer-copyright p {
    color: #888ea5;
    text-transform: capitalize;
    padding: 38px 0;
}

.hm-footer-copyright p a {
    color: #888ea5;
}

#scroll-Top .return-to-top {
    position: fixed;
    right: 30px;
    bottom: 90px;
    width: 40px;
    line-height: 40px;
    height: 40px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
    background: #d63384;
    border: 1px solid #d63384;
    border-radius: 50%;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    z-index: 2;
}

#scroll-Top .return-to-top a {
    color: #fff;
}

#scroll-Top .return-to-top:hover {
    background: #fff;
    /* color: #d63384; */
    border: 1px solid #d63384;
}

#scroll-Top .return-to-top a:hover {
    color: #d63384;
}

#scroll-Top .return-to-top i {
    position: relative;
    bottom: 0;
    position: relative;
    animation-name: example;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-duration: 1s;
}

@keyframes example {
    0% {
        bottom: 0px;
    }

    100% {
        bottom: 7px;
    }
}