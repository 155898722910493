@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  src:url("./assets/font/RobotoCondensed-Regular.woff") format("woff");
  font-display: swap;
}

* {
  padding: 0;
  margin: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  color: #454756;
  background: #fff;
  max-width: 1920px;
  margin: 0 auto;
  overflow-x: hidden;
}

a,
a:hover,
a:active,
a:focus {

  font-family: 'Roboto Condensed', sans-serif;
  text-decoration: none;
  color: #6a708e;
  
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #43485c;
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
}

p {
  margin: 0;
  line-height: 1.8;
  color: #43485c;
  font-size: 14px;
  font-family: 'Roboto Condensed', sans-serif;
}

li {
  font-family: 'Roboto Condensed', sans-serif;
}

table {
  font-family: 'Roboto Condensed', sans-serif;
}

img {
  border: none;
  max-width: 100%;
  height: auto;
}

ul {
  padding: 0;
  margin: 0 auto;
  list-style: none;
}

ul li {
  list-style: none;

}

select,
input,
textarea,
button {
  box-shadow: none;
  outline: 0 !important;
}

button {
  background: transparent;
  border: 0;
}

html,
body {
  height: 100%;
  position: relative;
}

[placeholder]:focus::-webkit-input-placeholder {
  -webkit-transition: opacity 0.3s 0.3s ease;
  -moz-transition: opacity 0.3s 0.3s ease;
  -ms-transition: opacity 0.3s 0.3s ease;
  -o-transition: opacity 0.3s 0.3s ease;
  transition: opacity 0.3s 0.3s ease;
  opacity: 0;
}

.fix {
  position: relative;
  clear: both;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.container:before,
.container:after {
  display: table;
  content: "";
  clear: both;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.row::after {
  content: "";
  clear: both;
  display: table;
}

[class*="col-"] {
  float: left;
  padding: 15px;
}

.col-1 {
  width: 8.33%;
}

.col-2 {
  width: 16.66%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33%;
}

.col-5 {
  width: 41.66%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33%;
}

.col-8 {
  width: 66.66%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.33%;
}

.col-11 {
  width: 91.66%;
}

.col-12 {
  width: 100%;
}

.no-overlay {
  overflow-y: hidden;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #555;
}