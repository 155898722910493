.project {
    padding-top: 50px;
    padding-bottom: 50px;
    border: 1px solid #e5ebf2;
    scroll-margin-top: 50px;
}

.profiles-content {
    display: flex;
}

.social-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.social-content:first-child {
    padding-top: 20px;
}

.profile-wrapper {
    padding: 0;
    width: 25%;
}

.social-txt {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    text-align: center;
    max-width: 90%;
}

.single-social {
    position: relative;
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #b2c1ce;
    overflow: hidden;
}

.profile-wrapper:last-child .single-social {
    border-right: none;
}

.social-icon-name {
    text-transform: capitalize;
    color: #636a82;
    font-size: 16px;
    padding-top:10px;
}

.single-social .social-no-border {
    border-right: none;
}

.profile-border {
    border-bottom: 1px solid #b2c1ce;
}


@media (max-width:768px) {
    .profile-wrapper {
        display: flex;
        width: 50%;
    }

    .single-profile {
        border: none;
        margin: 0 auto;
    }

    .profile-border {
        display: none;
    }

    .profiles-content {
        padding-top: 0px;
        display: block;
    }

    .profiles-content:first-child {
        padding-top: 0;
    }

    .profiles {
        padding: 20px 0 0px;
    }

    .profiles .section-heading h2 {
        padding-bottom: 10px;
    }

    .single-social {
        border-right: none;
    }
}
.single-project {
    position: relative;
    width: 100%;
    height: 230px;
    display: flex;
    justify-content: center;
    border-right: 1px solid #b2c1ce;
    overflow: hidden;
}

.no-border{
    border-left: 0px;
}