.about {
    padding: 50px 0 0px;
}

.about-content {
    padding-top: 100px;
}

.single-about-txt h3 {
    font-size: 20px;
    color: #3c4258;
    text-transform: initial;
    line-height: 1.8;
}

.single-about-txt p {
    font-size: 16px;
    color: #999fb3;
    padding: 35px 0 43px;
    border-bottom: 1px solid #999fb3;
}

.single-about-add-info {
    margin: 10px 0;
}

.single-about-add-info h3 {
    color: #636a82;
    font-size: 16px;
    text-transform: capitalize;
}

.single-about-add-info p {
    font-weight: 300;
    border: 0;
    padding: 0;

}

.single-about-img {
    position: relative;
    box-shadow: 0 5px 20px rgba(14, 25, 80, .30);
    background-image: url("../../assets//images//about//profile_image.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px;
    margin: auto;
}

.single-about-img:before {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(31, 44, 108, .3);
}

.single-about-img img {
    max-width: 500px;
    height: 468px;
}

.about-list-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90px;
    background: #1f2c6c;
}

.about-list-icon ul {
    display: flex;
    padding-left: 0;
}

.about-list-icon ul li a {
    cursor: pointer;
    color: #fff;
}

.about-list-icon ul li a {
    cursor: pointer;
    color: #fff;
    display: inline-block;
    border: 2px solid;
    margin: 0 10px;
    border-radius: 8px;
    width: 30px;
    height: 30px;
    line-height: 28px;
    text-align: center;
}

.about-list-icon ul li a i {
    margin-left: 6px;
}

.link {
    color: #d63384;
}

@media (max-width: 768px) {
    .profile_image {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .single-about-img {
        width: 100%;
    }

    .about-content {
        padding-top: 0px;
    }

    .info {
        height: 70px;
    }

    .single-about-txt h3 {
        font-size: 15px;
    }

    .about {
        padding: 20px 0 0px;
    }

    .about .section-heading h2 {
        padding-bottom: 20px;
    }
}