
.portfolio {
    padding-top: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #e5ebf2;
    scroll-margin-top: 50px;
}

.demo-text {
    text-decoration: none;
    text-align: center;
    color: #fff;
}

.portfolio-content {
    padding-top: 100px;
}

.portfolio-photo {
    display: flex;
    align-items: center;
}

.isotope:after {
    content: '';
    display: block;
    clear: both;
}

.isotope .item {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}

.portfolio-photo {
    display: flex;
}

.isotope .item img {
    width: 100%;
    height: 100%;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}

.isotope .item:hover img {
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
}

.isotope-overlay {
    padding: 20px;
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(31, 44, 108, 0.877);
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: center;
    flex-direction: row;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}

.isotope-overlay a {
    display: inline-block;
    color: #fff;
    font-size: 14px;
    text-align: center;
}

.isotope .item:hover .isotope-overlay {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    top: 0;
}

.demo-item-wrapper{
    width: '100%';
    height: 'auto';
}

.demo-overlay-link{
    color:'#f0a7f2';
}

.demo-overlay-cols{
    border:'1px solid #6619ff';
}

.demo-overlay-center{
    border:'1px solid #fc6847';
}

@media(max-width:768px) {
    .portfolio-content {
        padding-top: 0px;
    }

    .portfolio {
        padding: 20px 0 0px;
    }

    .photo {
        padding-bottom: 0px;
        padding-top: 0px;
    }

    .portfolio-photo {
        flex-direction: column;
    }

    .content-wrapper {
        padding-bottom: 0;
        padding-top: 0;
    }

    .portfolio-details .section-heading {
        margin-bottom: 10px;
    }

    .portfolio .section-heading h2 {
        padding-bottom: 10px;
    }
}