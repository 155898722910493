.update-section {
    margin-top: 50px;
}

.update-content {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.update-box {
    font-size: 20px;
    color: #3c4258;
    text-transform: initial;
    line-height: 1.8;
    width: 50%;
    list-style-type: circle;
}

.update-box:first-child {
    padding-right: 10px;
}

.update-box .list {
    list-style-type: circle;
}

@media(max-width:768px) {
    .update-box {
        width: 100%;
    }

    .update-box .list {
        width: 100%;
        font-size: 15px;
    }

    .update-content {
        display: block;
    }

    .update-box:first-child {
        padding-right: 0;
    }
}